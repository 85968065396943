// Download XLSX from https://docs.google.com/spreadsheets/d/1wQ3aDAzCTuWCyceUToyqzO57yr1fdTiOf08jiBtCags/edit#gid=0
// Convert XLSX to JSON from https://products.aspose.app/cells/conversion/xlsx-to-json
export const healthScoreData = [
  {
    ComponentName: 'metrics-json',
    ComponentVersion: '4.2.25',
    Identifiable: 'Yes',
    'Release Age': 85,
    'Current Release': '4.2.25',
    'Contributors Count': 207,
    'Commits Count': 3797,
    'Forks Count': 1800,
    'OpenSSF Scorecard': 6.1,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jetty-http',
    ComponentVersion: '11.0.19',
    Identifiable: 'Yes',
    'Release Age': 97,
    'Current Release': 3.1,
    'Contributors Count': 5,
    'Commits Count': 29375,
    'Forks Count': 1900,
    'OpenSSF Scorecard': 6.9,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'log4j-over-slf4j',
    ComponentVersion: '2.0.11',
    Identifiable: 'Yes',
    'Release Age': 66,
    'Current Release': 4.9,
    'Contributors Count': 300,
    'Commits Count': 23,
    'Forks Count': 71,
    'OpenSSF Scorecard': 4.1,
    Deprecated: 'Yes',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-core',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 39,
    'Current Release': 9.3,
    'Contributors Count': 22,
    'Commits Count': 12,
    'Forks Count': 4,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: '2022-01-01',
    EOS: '2022-01-01'
  },
  {
    ComponentName: 'j2objc-annotations',
    ComponentVersion: '2.8',
    Identifiable: 'Yes',
    'Release Age': 85,
    'Current Release': 8.4,
    'Contributors Count': 17,
    'Commits Count': 3009,
    'Forks Count': 96,
    'OpenSSF Scorecard': 8.2,
    Deprecated: 'Yes',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'metrics-logback',
    ComponentVersion: '4.2.25',
    Identifiable: 'Yes',
    'Release Age': 312,
    'Current Release': 5.1,
    'Contributors Count': 6,
    'Commits Count': 58,
    'Forks Count': 1082,
    'OpenSSF Scorecard': 4.5,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jackson-datatype-jsr310',
    ComponentVersion: '2.16.1',
    Identifiable: 'Yes',
    'Release Age': 22,
    'Current Release': 3.1,
    'Contributors Count': 88,
    'Commits Count': 576,
    'Forks Count': 8,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'Yes',
    Outdated: 'No',
    EOL: '2024-08-31',
    EOS: 'No'
  },
  {
    ComponentName: 'logback-core',
    ComponentVersion: '1.4.14',
    Identifiable: 'Yes',
    'Release Age': 83,
    'Current Release': 3.6,
    'Contributors Count': 1,
    'Commits Count': 92,
    'Forks Count': 87,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jcl-over-slf4j',
    ComponentVersion: '2.0.11',
    Identifiable: 'Yes',
    'Release Age': 1460,
    'Current Release': '1.12.0',
    'Contributors Count': 60,
    'Commits Count': 2174,
    'Forks Count': 236,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jetty-io',
    ComponentVersion: '11.0.19',
    Identifiable: 'Yes',
    'Release Age': 744,
    'Current Release': '11.0.21',
    'Contributors Count': 202,
    'Commits Count': 29779,
    'Forks Count': 1900,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jackson-jakarta-rs-json-provider',
    ComponentVersion: '2.16.1',
    Identifiable: 'Yes',
    'Release Age': 179,
    'Current Release': '1.7.0',
    'Contributors Count': 14,
    'Commits Count': 287,
    'Forks Count': 42,
    'OpenSSF Scorecard': 6.6,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jakarta.servlet-api',
    ComponentVersion: '5.0.0',
    Identifiable: 'Yes',
    'Release Age': 225,
    'Current Release': '4.0.0',
    'Contributors Count': 31,
    'Commits Count': 8135,
    'Forks Count': 222,
    'OpenSSF Scorecard': 1.6,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'Yes'
  },
  {
    ComponentName: 'jersey-common',
    ComponentVersion: '3.0.12',
    Identifiable: 'Yes',
    'Release Age': 197,
    'Current Release': '1.4.14',
    'Contributors Count': 122,
    'Commits Count': 4531,
    'Forks Count': 1300,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'logback-throttling-appender',
    ComponentVersion: '1.4.1',
    Identifiable: 'Yes',
    'Release Age': 169,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-util',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 205,
    'Current Release': '3.14.0',
    'Contributors Count': 210,
    'Commits Count': 7897,
    'Forks Count': 1500,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'javassist',
    ComponentVersion: '3.30.2-GA',
    Identifiable: 'Yes',
    'Release Age': 785,
    'Current Release': '3.1.0',
    'Contributors Count': 14,
    'Commits Count': 444,
    'Forks Count': 51,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jersey-container-servlet-core',
    ComponentVersion: '3.0.12',
    Identifiable: 'Yes',
    'Release Age': 200,
    'Current Release': '2.17.1',
    'Contributors Count': 23,
    'Commits Count': 892,
    'Forks Count': 77,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-servlets',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 165,
    'Current Release': '4.2.26',
    'Contributors Count': 207,
    'Commits Count': 3816,
    'Forks Count': 1800,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-jackson',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 1215,
    'Current Release': '2.1.3',
    'Contributors Count': 15,
    'Commits Count': 139,
    'Forks Count': 33,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jackson-module-jakarta-xmlbind-annotations',
    ComponentVersion: '2.16.1',
    Identifiable: 'Yes',
    'Release Age': 180,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'osgi-resource-locator',
    ComponentVersion: '1.0.3',
    Identifiable: 'Yes',
    'Release Age': 1460,
    'Current Release': '1.12.0',
    'Contributors Count': 60,
    'Commits Count': 2174,
    'Forks Count': 236,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-health',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 744,
    'Current Release': '11.0.21',
    'Contributors Count': 202,
    'Commits Count': 29779,
    'Forks Count': 1900,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jakarta.el-api',
    ComponentVersion: '4.0.0',
    Identifiable: 'Yes',
    'Release Age': 179,
    'Current Release': '1.7.0',
    'Contributors Count': 14,
    'Commits Count': 287,
    'Forks Count': 42,
    'OpenSSF Scorecard': 6.6,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jackson-jakarta-rs-base',
    ComponentVersion: '2.16.1',
    Identifiable: 'Yes',
    'Release Age': 225,
    'Current Release': '4.0.0',
    'Contributors Count': 31,
    'Commits Count': 8135,
    'Forks Count': 222,
    'OpenSSF Scorecard': 1.6,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'Yes'
  },
  {
    ComponentName: 'caffeine',
    ComponentVersion: '3.1.8',
    Identifiable: 'Yes',
    'Release Age': 197,
    'Current Release': '1.4.14',
    'Contributors Count': 122,
    'Commits Count': 4531,
    'Forks Count': 1300,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'argparse4j',
    ComponentVersion: '0.9.0',
    Identifiable: 'Yes',
    'Release Age': 169,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'metrics-annotation',
    ComponentVersion: '4.2.25',
    Identifiable: 'Yes',
    'Release Age': 205,
    'Current Release': '3.14.0',
    'Contributors Count': 210,
    'Commits Count': 7897,
    'Forks Count': 1500,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'error_prone_annotations',
    ComponentVersion: '2.24.1',
    Identifiable: 'Yes',
    'Release Age': 785,
    'Current Release': '3.1.0',
    'Contributors Count': 14,
    'Commits Count': 444,
    'Forks Count': 51,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'metrics-core',
    ComponentVersion: '4.2.25',
    Identifiable: 'Yes',
    'Release Age': 200,
    'Current Release': '2.17.1',
    'Contributors Count': 23,
    'Commits Count': 892,
    'Forks Count': 77,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'guava',
    ComponentVersion: '33.0.0-jre',
    Identifiable: 'Yes',
    'Release Age': 165,
    'Current Release': '4.2.26',
    'Contributors Count': 207,
    'Commits Count': 3816,
    'Forks Count': 1800,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jakarta.activation',
    ComponentVersion: '2.0.1',
    Identifiable: 'Yes',
    'Release Age': 1215,
    'Current Release': '2.1.3',
    'Contributors Count': 15,
    'Commits Count': 139,
    'Forks Count': 33,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'snakeyaml',
    ComponentVersion: '2.2',
    Identifiable: 'Yes',
    'Release Age': 180,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jakarta.inject-api',
    ComponentVersion: '2.0.1.MR',
    Identifiable: 'Yes',
    'Release Age': 1460,
    'Current Release': '1.12.0',
    'Contributors Count': 60,
    'Commits Count': 2174,
    'Forks Count': 236,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jersey-hk2',
    ComponentVersion: '3.0.12',
    Identifiable: 'Yes',
    'Release Age': 744,
    'Current Release': '11.0.21',
    'Contributors Count': 202,
    'Commits Count': 29779,
    'Forks Count': 1900,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'failureaccess',
    ComponentVersion: '1.0.1',
    Identifiable: 'Yes',
    'Release Age': 179,
    'Current Release': '1.7.0',
    'Contributors Count': 14,
    'Commits Count': 287,
    'Forks Count': 42,
    'OpenSSF Scorecard': 6.6,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'metrics-jetty11',
    ComponentVersion: '4.2.25',
    Identifiable: 'Yes',
    'Release Age': 225,
    'Current Release': '4.0.0',
    'Contributors Count': 31,
    'Commits Count': 8135,
    'Forks Count': 222,
    'OpenSSF Scorecard': 1.6,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'Yes'
  },
  {
    ComponentName: 'jul-to-slf4j',
    ComponentVersion: '2.0.11',
    Identifiable: 'Yes',
    'Release Age': 197,
    'Current Release': '1.4.14',
    'Contributors Count': 122,
    'Commits Count': 4531,
    'Forks Count': 1300,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jakarta.el',
    ComponentVersion: '4.0.2',
    Identifiable: 'Yes',
    'Release Age': 169,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'hk2-api',
    ComponentVersion: '3.0.6',
    Identifiable: 'Yes',
    'Release Age': 205,
    'Current Release': '3.14.0',
    'Contributors Count': 210,
    'Commits Count': 7897,
    'Forks Count': 1500,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'commons-text',
    ComponentVersion: '1.11.0',
    Identifiable: 'Yes',
    'Release Age': 785,
    'Current Release': '3.1.0',
    'Contributors Count': 14,
    'Commits Count': 444,
    'Forks Count': 51,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jackson-databind',
    ComponentVersion: '2.16.1',
    Identifiable: 'Yes',
    'Release Age': 200,
    'Current Release': '2.17.1',
    'Contributors Count': 23,
    'Commits Count': 892,
    'Forks Count': 77,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-metrics',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 165,
    'Current Release': '4.2.26',
    'Contributors Count': 207,
    'Commits Count': 3816,
    'Forks Count': 1800,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jackson-module-parameter-names',
    ComponentVersion: '2.16.1',
    Identifiable: 'Yes',
    'Release Age': 1215,
    'Current Release': '2.1.3',
    'Contributors Count': 15,
    'Commits Count': 139,
    'Forks Count': 33,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jakarta.ws.rs-api',
    ComponentVersion: '3.0.0',
    Identifiable: 'Yes',
    'Release Age': 180,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jackson-dataformat-yaml',
    ComponentVersion: '2.16.1',
    Identifiable: 'Yes',
    'Release Age': 1460,
    'Current Release': '1.12.0',
    'Contributors Count': 60,
    'Commits Count': 2174,
    'Forks Count': 236,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jetty-server',
    ComponentVersion: '11.0.19',
    Identifiable: 'Yes',
    'Release Age': 744,
    'Current Release': '11.0.21',
    'Contributors Count': 202,
    'Commits Count': 29779,
    'Forks Count': 1900,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'profiler',
    ComponentVersion: '1.1.1',
    Identifiable: 'Yes',
    'Release Age': 179,
    'Current Release': '1.7.0',
    'Contributors Count': 14,
    'Commits Count': 287,
    'Forks Count': 42,
    'OpenSSF Scorecard': 6.6,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jackson-datatype-jdk8',
    ComponentVersion: '2.16.1',
    Identifiable: 'Yes',
    'Release Age': 225,
    'Current Release': '4.0.0',
    'Contributors Count': 31,
    'Commits Count': 8135,
    'Forks Count': 222,
    'OpenSSF Scorecard': 1.6,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'Yes'
  },
  {
    ComponentName: 'hk2-locator',
    ComponentVersion: '3.0.6',
    Identifiable: 'Yes',
    'Release Age': 197,
    'Current Release': '1.4.14',
    'Contributors Count': 122,
    'Commits Count': 4531,
    'Forks Count': 1300,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jersey-bean-validation',
    ComponentVersion: '3.0.12',
    Identifiable: 'Yes',
    'Release Age': 169,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jboss-logging',
    ComponentVersion: '3.5.3.Final',
    Identifiable: 'Yes',
    'Release Age': 205,
    'Current Release': '3.14.0',
    'Contributors Count': 210,
    'Commits Count': 7897,
    'Forks Count': 1500,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jetty-security',
    ComponentVersion: '11.0.19',
    Identifiable: 'Yes',
    'Release Age': 785,
    'Current Release': '3.1.0',
    'Contributors Count': 14,
    'Commits Count': 444,
    'Forks Count': 51,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'listenablefuture',
    ComponentVersion: '9999.0-empty-to-avoid-conflict-with-guava',
    Identifiable: 'Yes',
    'Release Age': 200,
    'Current Release': '2.17.1',
    'Contributors Count': 23,
    'Commits Count': 892,
    'Forks Count': 77,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-logging',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 165,
    'Current Release': '4.2.26',
    'Contributors Count': 207,
    'Commits Count': 3816,
    'Forks Count': 1800,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jersey-metainf-services',
    ComponentVersion: '3.0.12',
    Identifiable: 'Yes',
    'Release Age': 1215,
    'Current Release': '2.1.3',
    'Contributors Count': 15,
    'Commits Count': 139,
    'Forks Count': 33,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jersey-client',
    ComponentVersion: '3.0.12',
    Identifiable: 'Yes',
    'Release Age': 180,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-configuration',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 1460,
    'Current Release': '1.12.0',
    'Contributors Count': 60,
    'Commits Count': 2174,
    'Forks Count': 236,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'slf4j-api',
    ComponentVersion: '2.0.11',
    Identifiable: 'Yes',
    'Release Age': 744,
    'Current Release': '11.0.21',
    'Contributors Count': 202,
    'Commits Count': 29779,
    'Forks Count': 1900,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-jersey',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 179,
    'Current Release': '1.7.0',
    'Contributors Count': 14,
    'Commits Count': 287,
    'Forks Count': 42,
    'OpenSSF Scorecard': 6.6,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-request-logging',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 225,
    'Current Release': '4.0.0',
    'Contributors Count': 31,
    'Commits Count': 8135,
    'Forks Count': 222,
    'OpenSSF Scorecard': 1.6,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'Yes'
  },
  {
    ComponentName: 'jersey-server',
    ComponentVersion: '3.0.12',
    Identifiable: 'Yes',
    'Release Age': 197,
    'Current Release': '1.4.14',
    'Contributors Count': 122,
    'Commits Count': 4531,
    'Forks Count': 1300,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jackson-core',
    ComponentVersion: '2.16.1',
    Identifiable: 'Yes',
    'Release Age': 169,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jackson-datatype-guava',
    ComponentVersion: '2.16.1',
    Identifiable: 'Yes',
    'Release Age': 205,
    'Current Release': '3.14.0',
    'Contributors Count': 210,
    'Commits Count': 7897,
    'Forks Count': 1500,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jakarta.xml.bind-api',
    ComponentVersion: '3.0.1',
    Identifiable: 'Yes',
    'Release Age': 785,
    'Current Release': '3.1.0',
    'Contributors Count': 14,
    'Commits Count': 444,
    'Forks Count': 51,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-validation',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 200,
    'Current Release': '2.17.1',
    'Contributors Count': 23,
    'Commits Count': 892,
    'Forks Count': 77,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jakarta.annotation-api',
    ComponentVersion: '2.0.0',
    Identifiable: 'Yes',
    'Release Age': 165,
    'Current Release': '4.2.26',
    'Contributors Count': 207,
    'Commits Count': 3816,
    'Forks Count': 1800,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jetty-servlets',
    ComponentVersion: '11.0.19',
    Identifiable: 'Yes',
    'Release Age': 1215,
    'Current Release': '2.1.3',
    'Contributors Count': 15,
    'Commits Count': 139,
    'Forks Count': 33,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'hk2-utils',
    ComponentVersion: '3.0.6',
    Identifiable: 'Yes',
    'Release Age': 180,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'commons-text',
    ComponentVersion: '1.9',
    Identifiable: 'Yes',
    'Release Age': 1460,
    'Current Release': '1.12.0',
    'Contributors Count': 60,
    'Commits Count': 2174,
    'Forks Count': 236,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'http2-common',
    ComponentVersion: '9.4.48.v20220622',
    Identifiable: 'Yes',
    'Release Age': 744,
    'Current Release': '11.0.21',
    'Contributors Count': 202,
    'Commits Count': 29779,
    'Forks Count': 1900,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'classmate',
    ComponentVersion: '1.7.0',
    Identifiable: 'Yes',
    'Release Age': 179,
    'Current Release': '1.7.0',
    'Contributors Count': 14,
    'Commits Count': 287,
    'Forks Count': 42,
    'OpenSSF Scorecard': 6.6,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jersey-container-servlet',
    ComponentVersion: '3.0.12',
    Identifiable: 'Yes',
    'Release Age': 225,
    'Current Release': '4.0.0',
    'Contributors Count': 31,
    'Commits Count': 8135,
    'Forks Count': 222,
    'OpenSSF Scorecard': 1.6,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'Yes'
  },
  {
    ComponentName: 'logback-access',
    ComponentVersion: '1.4.14',
    Identifiable: 'Yes',
    'Release Age': 197,
    'Current Release': '1.4.14',
    'Contributors Count': 122,
    'Commits Count': 4531,
    'Forks Count': 1300,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-lifecycle',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 169,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'commons-lang3',
    ComponentVersion: '3.14.0',
    Identifiable: 'Yes',
    'Release Age': 205,
    'Current Release': '3.14.0',
    'Contributors Count': 210,
    'Commits Count': 7897,
    'Forks Count': 1500,
    'OpenSSF Scorecard': 8.1,
    Deprecated: 'No',
    Outdated: 'No',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jakarta.validation-api',
    ComponentVersion: '3.0.2',
    Identifiable: 'Yes',
    'Release Age': 785,
    'Current Release': '3.1.0',
    'Contributors Count': 14,
    'Commits Count': 444,
    'Forks Count': 51,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jackson-module-blackbird',
    ComponentVersion: '2.16.1',
    Identifiable: 'Yes',
    'Release Age': 200,
    'Current Release': '2.17.1',
    'Contributors Count': 23,
    'Commits Count': 892,
    'Forks Count': 77,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'metrics-jersey3',
    ComponentVersion: '4.2.25',
    Identifiable: 'Yes',
    'Release Age': 165,
    'Current Release': '4.2.26',
    'Contributors Count': 207,
    'Commits Count': 3816,
    'Forks Count': 1800,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'jakarta.activation-api',
    ComponentVersion: '2.0.1',
    Identifiable: 'Yes',
    'Release Age': 1215,
    'Current Release': '2.1.3',
    'Contributors Count': 15,
    'Commits Count': 139,
    'Forks Count': 33,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  },
  {
    ComponentName: 'dropwizard-jetty',
    ComponentVersion: '4.0.6',
    Identifiable: 'Yes',
    'Release Age': 180,
    'Current Release': '5.0.0',
    'Contributors Count': 406,
    'Commits Count': 8335,
    'Forks Count': 3400,
    Deprecated: 'No',
    Outdated: 'Yes',
    EOL: 'No',
    EOS: 'No'
  }
]
