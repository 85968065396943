import { useCallback, useEffect, useState } from 'react'

import { InfoIcon } from '@chakra-ui/icons'
import { Flex, FormLabel, Icon, useDisclosure } from '@chakra-ui/react'

import InfoModal from '../InfoModal'

const InfoLabel = ({ title, name }) => {
  const [onClick, setOnClick] = useState(() => () => {})

  useEffect(() => {
    switch (title) {
      case 'Status':
        setOnClick(() => onCheckStatus)
        break
      case 'Justification':
        setOnClick(() => onCheckJustify)
        break
      case 'Impact Statement':
        setOnClick(() => onCheckImpact)
        break
      case 'Action Statement':
        setOnClick(() => onCheckAction)
        break
      case 'Response':
        setOnClick(() => onCheckResponse)
        break
      case 'Fixed Version':
        setOnClick(() => onCheckFixedVersion)
        break
      case 'Details':
        setOnClick(() => onCheckDetails)
        break
      case 'Internal Notes':
        setOnClick(() => onCheckNotes)
        break
      default:
        setOnClick(() => () => {})
    }
  }, [
    onCheckAction,
    onCheckDetails,
    onCheckFixedVersion,
    onCheckImpact,
    onCheckJustify,
    onCheckNotes,
    onCheckResponse,
    onCheckStatus,
    title
  ])

  const [infoHeading, setInfoHeading] = useState('')
  const [infoText, setInfoText] = useState('')
  const [infoUrl, setInfoUrl] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onCheckStatus = useCallback(() => {
    setInfoHeading(`Status`)
    setInfoText(
      `Declares the current state of an occurrence of a vulnerability, after automated or manual analysis.`
    )
    setInfoUrl(``)
    onOpen()
  }, [onOpen])

  const onCheckJustify = useCallback(() => {
    setInfoHeading(`Justification`)
    setInfoText(`For statements conveying a "not affected" status, a VEX statement MUST include either a status justification or
      an impact statement informing why the product is not affected by the vulnerability.
      Justifications are fixed labels defined by VEX. See Status Justifications below for valid values.`)
    setInfoUrl(``)
    onOpen()
  }, [onOpen])

  const onCheckResponse = useCallback(() => {
    setInfoHeading(`Response`)
    setInfoText(
      `A response to the vulnerability by the manufacturer, supplier, or project responsible for the affected component or service. Responses are strongly encouraged for vulnerabilities where the analysis state is exploitable.`
    )
    setInfoUrl(``)
    onOpen()
  }, [onOpen])

  const onCheckFixedVersion = useCallback(() => {
    setInfoHeading(`Fixed Version`)
    setInfoText(
      `For "affected" status with "update" response, Fixed Version can be used to indicate which version of the product includes a fix.`
    )
    setInfoUrl(``)
    onOpen()
  }, [onOpen])

  const onCheckImpact = useCallback(() => {
    setInfoHeading(`Impact Statement`)
    setInfoText(`For status “not affected”, if justification is not provided, an impact statement must be included \
      that further explains how or why the listed product is “not affected” by this vulnerability. Impact Statement is optional if a justification is provided.`)
    setInfoUrl(``)
    onOpen()
  }, [onOpen])

  const onCheckAction = useCallback(() => {
    setInfoHeading(`Action Statement`)
    setInfoText(
      `For status “affected”, an action statement must be included that describes actions to remediate or mitigate the vulnerability.`
    )
    setInfoUrl(``)
    onOpen()
  }, [onOpen])

  const onCheckDetails = useCallback(() => {
    setInfoHeading(`Details`)
    setInfoText(
      `Detailed description of the impact including methods used during assessment. If a vulnerability is not exploitable, this field should include specific details on why the component or service is not impacted by this vulnerability.`
    )
    setInfoUrl(``)
    onOpen()
  }, [onOpen])

  const onCheckNotes = useCallback(() => {
    setInfoHeading(`Internal Notes`)
    setInfoText(` Internal notes or observations made by the team handling the vulnerability, which may include additional context, discussions, or considerations relevant to the analysis or response process. \
      These notes are not exported with the product SBOM or VEX are only used for internal communication and documentation purposes.`)
    setInfoUrl(``)
    onOpen()
  }, [onOpen])

  return (
    <>
      <Flex flexDirection={'row'} alignItems={'center'} gap={2} mb={1}>
        <FormLabel m={0} p={0} fontSize='sm' htmlFor={name}>
          {title}
        </FormLabel>
        <Icon
          as={InfoIcon}
          color={'blue.500'}
          cursor={'pointer'}
          onClick={onClick}
        />
      </Flex>
      {isOpen && (
        <InfoModal
          isOpen={isOpen}
          onClose={onClose}
          heading={infoHeading}
          body={infoText}
          url={infoUrl}
        />
      )}
    </>
  )
}

export default InfoLabel
