import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { validateEmail, validateUrl } from 'utils'

import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'

import { RegisterOrganization } from 'graphQL/Mutation'

const OrgModal = ({ isOpen, onClose, org, onSwitch }) => {
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [urlError, setUrlError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  const disableButtonTemporarily = () => {
    setIsDisabled(true)
    setTimeout(() => {
      setIsDisabled(false)
    }, 3000)
  }

  const containsSpace = /\s/.test(url)

  const [registerOrg] = useMutation(RegisterOrganization)

  const handleCheckEmail = () => {
    if (!validateEmail(email)) {
      setEmailError('Email is invalid')
    }
  }

  const handleCheckUrl = () => {
    if (!validateUrl(url)) {
      setUrlError('Please enter a valid URL')
    }
  }

  const handleCreate = () => {
    disableButtonTemporarily()
    registerOrg({
      variables: {
        name,
        url,
        email
      }
    }).then((res) => {
      const { errors } = res?.data?.RegisterOrganization || ''
      if (errors?.length > 0) {
        setError(errors[0])
      } else {
        if (org) {
          onClose()
        } else {
          const orgId = res?.data?.organizationCreate?.organization?.id
          onSwitch(orgId, name)
        }
      }
    })
  }

  const isInvalid =
    isDisabled ||
    name === '' ||
    (email !== '' && emailError !== '') ||
    (url !== '' && !validateUrl(url))

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Register Organization</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex width={'100%'} direction={'column'} gap={4}>
            {error !== '' && (
              <Alert status='error'>
                <AlertIcon />
                <Text fontSize={'sm'}>{error}</Text>
              </Alert>
            )}
            {/* NAME */}
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                  setError('')
                }}
                placeholder='Enter name'
              />
            </FormControl>
            {/* URL */}
            <FormControl
              isInvalid={(url !== '' && !validateUrl(url)) || containsSpace}
            >
              <FormLabel>URL</FormLabel>
              <Input
                type='text'
                value={url}
                onBlur={handleCheckUrl}
                onChange={(e) => {
                  setUrl(e.target.value)
                  setUrlError('')
                }}
                placeholder='Enter url'
              />
              {urlError !== '' && (
                <FormErrorMessage>{urlError}</FormErrorMessage>
              )}
            </FormControl>
            {/* EMAIL */}
            <FormControl isInvalid={email !== '' && !validateEmail(email)}>
              <FormLabel>Email</FormLabel>
              <Input
                type='text'
                value={email}
                onBlur={handleCheckEmail}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setEmailError('')
                }}
                placeholder='Enter email address'
              />
              {emailError !== '' && (
                <FormErrorMessage>{emailError}</FormErrorMessage>
              )}
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme='blue'
            isDisabled={isInvalid}
            onClick={handleCreate}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default OrgModal
