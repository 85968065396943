export const defaultData = {
  name: 'Product A',
  children: [
    {
      name: 'Version 1.0',
      children: [
        {
          name: 'Parts',
          children: [
            {
              name: 'Part 1',
              children: [
                {
                  name: 'Component A',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component B',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            },
            {
              name: 'Part 2',
              children: [
                {
                  name: 'Component C',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component D',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            }
          ]
        },
        {
          name: 'Components',
          children: [
            {
              name: 'Component A',
              attributes: {
                type: 'Hardware'
              }
            },
            {
              name: 'Component F',
              attributes: {
                type: 'Software'
              }
            }
          ]
        },
        {
          name: 'Vulnerabilities',
          children: [
            {
              name: 'Vulnerability 1',
              attributes: {
                severity: 'High'
              }
            },
            {
              name: 'Vulnerability 2',
              attributes: {
                severity: 'Medium'
              }
            }
          ]
        }
      ]
    },
    {
      name: 'Version 2.0',
      children: [
        {
          name: 'Parts',
          children: [
            {
              name: 'Part 3',
              children: [
                {
                  name: 'Component G',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component H',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            },
            {
              name: 'Part 4',
              children: [
                {
                  name: 'Component I',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component A',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            }
          ]
        },
        {
          name: 'Components',
          children: [
            {
              name: 'Component K',
              attributes: {
                type: 'Hardware'
              }
            },
            {
              name: 'Component L',
              attributes: {
                type: 'Software'
              }
            }
          ]
        },
        {
          name: 'Vulnerabilities',
          children: [
            {
              name: 'Vulnerability 1',
              attributes: {
                severity: 'Low'
              }
            },
            {
              name: 'Vulnerability 4',
              attributes: {
                severity: 'High'
              }
            }
          ]
        }
      ]
    }
  ]
}

export const developmentData = {
  name: 'Product A',
  children: [
    {
      name: 'Version 1.0',
      children: [
        {
          name: 'Parts',
          children: [
            {
              name: 'Part 1',
              children: [
                {
                  name: 'Component A',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component B',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            },
            {
              name: 'Part 2',
              children: [
                {
                  name: 'Component E',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component F',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            }
          ]
        },
        {
          name: 'Components',
          children: [
            {
              name: 'Component A',
              attributes: {
                type: 'Hardware'
              }
            },
            {
              name: 'Component B',
              attributes: {
                type: 'Software'
              }
            },
            {
              name: 'Component J',
              attributes: {
                type: 'Hardware'
              }
            }
          ]
        },
        {
          name: 'Vulnerabilities',
          children: [
            {
              name: 'Vulnerability 1',
              attributes: {
                severity: 'High'
              }
            },
            {
              name: 'Vulnerability 3',
              attributes: {
                severity: 'Low'
              }
            }
          ]
        }
      ]
    },
    {
      name: 'Version 2.0',
      children: [
        {
          name: 'Parts',
          children: [
            {
              name: 'Part 3',
              children: [
                {
                  name: 'Component K',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component L',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            },
            {
              name: 'Part 4',
              children: [
                {
                  name: 'Component M',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component N',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            }
          ]
        },
        {
          name: 'Components',
          children: [
            {
              name: 'Component G',
              attributes: {
                type: 'Hardware'
              }
            },
            {
              name: 'Component H',
              attributes: {
                type: 'Software'
              }
            }
          ]
        },
        {
          name: 'Vulnerabilities',
          children: [
            {
              name: 'Vulnerability 2',
              attributes: {
                severity: 'Medium'
              }
            },
            {
              name: 'Vulnerability 4',
              attributes: {
                severity: 'High'
              }
            }
          ]
        }
      ]
    }
  ]
}

export const productionData = {
  name: 'Product A',
  children: [
    {
      name: 'Version 1.0',
      children: [
        {
          name: 'Parts',
          children: [
            {
              name: 'Part 1',
              children: [
                {
                  name: 'Component A',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component B',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            },
            {
              name: 'Part 2',
              children: [
                {
                  name: 'Component C',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component D',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            }
          ]
        },
        {
          name: 'Components',
          children: [
            {
              name: 'Component A',
              attributes: {
                type: 'Hardware'
              }
            },
            {
              name: 'Component B',
              attributes: {
                type: 'Software'
              }
            }
          ]
        },
        {
          name: 'Vulnerabilities',
          children: [
            {
              name: 'Vulnerability 1',
              attributes: {
                severity: 'High'
              }
            },
            {
              name: 'Vulnerability 3',
              attributes: {
                severity: 'Low'
              }
            }
          ]
        }
      ]
    },
    {
      name: 'Version 2.0',
      children: [
        {
          name: 'Parts',
          children: [
            {
              name: 'Part 3',
              children: [
                {
                  name: 'Component G',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component H',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            },
            {
              name: 'Part 4',
              children: [
                {
                  name: 'Component I',
                  attributes: {
                    type: 'Hardware'
                  }
                },
                {
                  name: 'Component J',
                  attributes: {
                    type: 'Software'
                  }
                }
              ]
            }
          ]
        },
        {
          name: 'Components',
          children: [
            {
              name: 'Component K',
              attributes: {
                type: 'Hardware'
              }
            },
            {
              name: 'Component L',
              attributes: {
                type: 'Software'
              }
            }
          ]
        },
        {
          name: 'Vulnerabilities',
          children: [
            {
              name: 'Vulnerability 2',
              attributes: {
                severity: 'Medium'
              }
            },
            {
              name: 'Vulnerability 4',
              attributes: {
                severity: 'High'
              }
            }
          ]
        }
      ]
    }
  ]
}
