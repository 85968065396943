import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useLocation, useParams } from 'react-router-dom'
import { customStyles, getFullDateAndTime, sevColor, timeSince } from 'utils'
import CpeModal from 'views/Dashboard/Products/components/CpeModal'
import PurlModal from 'views/Dashboard/Products/components/PurlModal'
import CheckModal from 'views/Sbom/components/CheckModal'
import PriSupplierModal from 'views/Sbom/components/PriSupplierModal'
import SearchFilter from 'views/Sbom/components/SearchFilter'
import SupplierModal from 'views/Sbom/components/SupplierModal'

import { CheckIcon, RepeatIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Stack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'

import CustomLoader from 'components/CustomLoader'
import GeneralDataDrawer from 'components/Drawer/GeneralDataDrawer'
import RelationshipDrawer from 'components/Drawer/RelationshipDrawer'
import LicenseModal from 'components/LicenseModal'
import Pagination from 'components/Pagination'
import RowComponent from 'components/RowComponent'

import useCustomToast from 'hooks/useCustomToast'
import { useGlobalState } from 'hooks/useGlobalState'
import { useHasPermission } from 'hooks/useHasPermission'
import { usePaginatatedQuery } from 'hooks/usePaginatatedQuery'

import {
  UpdateComponent,
  checkResultUpdate,
  recheckHealth,
  sbomUpdate
} from 'graphQL/Mutation'
import {
  CpeAutoComplete,
  GetCheckFilterData,
  GetCheckResults,
  GetExistingRules,
  GetProductData
} from 'graphQL/Queries'
import { GetComponentPath } from 'graphQL/Queries'

import { BiSolidWrench } from 'react-icons/bi'
import { FaCheckDouble } from 'react-icons/fa'
import { GoSkip } from 'react-icons/go'

import FixedModal from '../components/FixedModal'
import CheckFilters from './CheckFilters'

const Checks = () => {
  const { showToast } = useCustomToast()
  const params = useParams()
  const productId = params.productid
  const sbomId = params.sbomid
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const activeTab = queryParams.get('tab')
  const customerView = location.pathname.startsWith('/customer')

  const headColor = useColorModeValue('#4A5568', '#CBD5E0')
  const textColor = useColorModeValue('#1A202C', '#F7FAFC')

  const { dispatch } = useGlobalState()
  const { prodCompDispatch, sbomDispatch } = dispatch

  const { data: prodData } = useQuery(GetProductData, {
    skip: activeTab === 'checks' ? false : true,
    variables: { projectId: productId, sbomId }
  })

  const [getComPath, { data: relation }] = useLazyQuery(GetComponentPath)
  const { pathToPrimary } = relation?.component || ''

  const { sbom: sbomData } = prodData || ''

  const [checkState, setCheckState] = useState({
    field: 'CHECK_RESULTS_UPDATED_AT',
    direction: 'DESC'
  })

  const { nodes, paginationProps, refetch, loading, reset } =
    usePaginatatedQuery(GetCheckResults, {
      skip: activeTab === 'checks' ? false : true,
      selector: 'sbom.checkResults',
      variables: {
        sbomId: sbomId,
        projectId: productId,
        ...checkState
      }
    })

  // GET HEALTH CHECK FILTER HEADS
  const { data: filterHead } = useQuery(GetCheckFilterData, {
    fetchPolicy: 'network-only',
    skip: activeTab === 'checks' ? false : true,
    variables: {
      projectId: productId,
      sbomId
    }
  })

  const editChecks = useHasPermission({
    parentKey: 'view_sbom',
    childKey: 'edit_checks'
  })

  const updateComp = useHasPermission({
    parentKey: 'view_sbom',
    childKey: 'update_sbom_components'
  })

  const [purlValue, setPurlValue] = useState('')
  const [cpeList, setCpeList] = useState([])
  const [cpeValue, setCpeValue] = useState('')
  const [selectedCpe, setSelectedCpe] = useState(null)
  const [checkSearch, setCheckSearch] = useState('')
  const [activeRow, setActiveRow] = useState(null)
  const [ruleExists, setRuleExists] = useState(false)

  const [getCpe] = useLazyQuery(CpeAutoComplete)
  const [getRules, { loading: loadingRules }] = useLazyQuery(GetExistingRules)
  const [updateResult] = useMutation(checkResultUpdate)
  const [updateComponent] = useMutation(UpdateComponent)
  const [healthRecheck] = useMutation(recheckHealth)
  const [updateSbom] = useMutation(sbomUpdate)

  const creationToolBtn = useRef(null)
  const authorBtn = useRef(null)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isOpen: isLicenseOpen,
    onOpen: onLicenseOpen,
    onClose: onLicenseClose
  } = useDisclosure()

  const {
    isOpen: isDataLicenseOpen,
    onOpen: onDataLicenseOpen,
    onClose: onDataLicenseClose
  } = useDisclosure()

  const {
    isOpen: isCreationOpen,
    onOpen: onCreationOpen,
    onClose: onCreationClose
  } = useDisclosure()

  const {
    isOpen: isDocSupOpen,
    onOpen: onDocSupOpen,
    onClose: onDocSupClose
  } = useDisclosure()

  const {
    isOpen: isTypeOpen,
    onOpen: onTypeOpen,
    onClose: onTypeClose
  } = useDisclosure()

  const {
    isOpen: isAuthorOpen,
    onOpen: onAuthorOpen,
    onClose: onAuthorClose
  } = useDisclosure()

  const {
    isOpen: isSupplierOpen,
    onOpen: onSupplierOpen,
    onClose: onSupplierClose
  } = useDisclosure()

  const {
    isOpen: isPrimaryOpen,
    onOpen: onPrimaryOpen,
    onClose: onPrimaryClose
  } = useDisclosure()

  const {
    isOpen: isPurlOpen,
    onOpen: onPurlOpen,
    onClose: onPurlClose
  } = useDisclosure()

  const {
    isOpen: isCpeOpen,
    onOpen: onCpeOpen,
    onClose: onCpeClose
  } = useDisclosure()

  const {
    isOpen: isFixedOpen,
    onOpen: onFixedOpen,
    onClose: onFixedClose
  } = useDisclosure()

  const {
    isOpen: isVersionOpen,
    onOpen: onVersionOpen,
    onClose: onVersionClose
  } = useDisclosure()

  const {
    isOpen: isRelOpen,
    onOpen: onRelOpen,
    onClose: onRelClose
  } = useDisclosure()

  /*   const handleRefetch = useCallback(() => {
    showToast({
      description:
        'Checks rescan is in progress. Please refresh the page to see the updated results.',
      status: 'info'
    })
    reset()
    refetch()
  }, [refetch, reset, showToast]) */

  const handleReCheck = useCallback(async () => {
    await healthRecheck({
      variables: {
        sbomId: sbomId
      }
    }).then((res) => {
      if (res.data) {
        showToast({
          description: 'Health re-check successfully',
          status: 'success'
        })
      }
    })
  }, [healthRecheck, sbomId, showToast])

  const setSearchFilter = useCallback(
    (value) => {
      setCheckState((oldFilter) => ({
        ...oldFilter,
        search: value
      }))
      reset()
    },
    [reset]
  )

  // CLEAR SERACH
  const handleClear = useCallback(() => {
    setCheckSearch('')
    setCheckState((oldFilter) => ({
      ...oldFilter,
      search: undefined
    }))
    reset()
  }, [reset])

  // ON SEARCH INPUT CHANGE
  const onSearchInputChange = useCallback(
    (e) => {
      const { value } = e.target
      if (value === '') {
        handleClear()
      } else {
        setCheckSearch(value)
      }
    },
    [handleClear]
  )

  // SEARCH COMPONENT
  const handleSearch = useCallback(
    (event) => {
      const {
        key,
        target: { value }
      } = event
      if (key === 'Enter' && value !== '') {
        setSearchFilter(value)
      }
    },
    [setSearchFilter]
  )

  // SUB HEADER
  const subHeader = useMemo(() => {
    return (
      <Flex
        width={'100%'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack
          width={'100%'}
          direction={'row'}
          spacing={3}
          alignItems={'flex-start'}
        >
          {/* SEARCH COMPONENTS */}
          <SearchFilter
            id='healthcheck'
            filterText={checkSearch}
            onChange={onSearchInputChange}
            onFilter={handleSearch}
            onClear={handleClear}
          />

          {/* FILTER COMPONENTS BASED ON ECOSYSTEM */}
          {filterHead && (
            <CheckFilters
              filters={filterHead?.sbom?.filters}
              setCheckState={(newFilters) => {
                setCheckState(newFilters)
                reset()
              }}
            />
          )}
        </Stack>

        <Stack spacing={3} direction={'row'}>
          <Tooltip label='Re-Check'>
            <IconButton
              fontSize={'sm'}
              variant='solid'
              colorScheme='blue'
              fontWeight='normal'
              onClick={handleReCheck}
              isDisabled={!editChecks}
              icon={<FaCheckDouble size={16} />}
            />
          </Tooltip>
          <Tooltip label='Refresh'>
            <IconButton
              colorScheme='blue'
              icon={<RepeatIcon />}
              onClick={() => refetch()}
            />
          </Tooltip>
        </Stack>
      </Flex>
    )
  }, [
    checkSearch,
    onSearchInputChange,
    handleSearch,
    handleClear,
    filterHead,
    handleReCheck,
    editChecks,
    reset,
    refetch
  ])

  const handleOpenLicense = () => {
    prodCompDispatch({ type: 'CLEAR_LICENSES' })
    onLicenseOpen()
  }

  const handleComUpdate = async (row) => {
    await updateComponent({
      variables: {
        id: row.componentId,
        sbomId: sbomId,
        uniqueId: true
      }
    })
      .then((res) => {
        if (res.data) {
          healthRecheck({
            variables: {
              checkId: row.organizationRule.rule.friendlyId,
              compId: row.componentId,
              sbomId: sbomId
            }
          })
        }
      })
      .finally(() => {
        setTimeout(() => {
          showToast({
            description: 'A unique identifier has been added to the component',
            status: 'success'
          })
        }, 1000)
      })
  }

  const handleSbomUpdate = async (row) => {
    await updateSbom({
      variables: {
        id: row.sbomId,
        spec: row.sbom.spec,
        generateUniqueId: true
      }
    })
      .then((res) => {
        if (res.data) {
          healthRecheck({
            variables: {
              checkId: row.organizationRule.rule.friendlyId,
              sbomId: sbomId
            }
          })
        }
      })
      .finally(() => {
        setTimeout(() => {
          showToast({
            description: 'A unique identifier has been added to the component',
            status: 'success'
          })
        }, 1000)
      })
  }

  const handleOpen = (row) => {
    setActiveRow(row)
    const { organizationRule } = row
    const { shortDesc } = organizationRule?.rule || ''

    // TIMESTAMP SELECTOR UI
    if (shortDesc === 'Document creation timestamp') {
      return onOpen()
    }

    // CREATION TOOL SIDE DRAWER
    if (shortDesc === 'Document has creation tools present') {
      return onCreationOpen()
    }

    // AUTHOR SIDE DRAWER
    if (shortDesc === 'Document has authors present') {
      return onAuthorOpen()
    }

    // SUPPLIER SIDE DRAWER
    if (shortDesc === 'Document has suppliers present') {
      return onDocSupOpen()
    }

    // SUPPLIER SIDE DRAWER
    if (shortDesc === 'Document has data license specified') {
      sbomDispatch({ type: 'CLEAR_LICENSES' })
      return onDataLicenseOpen()
    }

    // PRIMARY COMPONENT SELECTOR MODAL
    if (shortDesc === 'Document has a primary component') {
      return onPrimaryOpen()
    }

    // COMPONENT TYPE SELECTOR MODAL
    if (
      shortDesc === 'Component has a valid type' ||
      shortDesc === 'Component has a type'
    ) {
      return onTypeOpen()
    }

    // COMPONENT VERSION SELECTOR MODAL
    if (shortDesc === 'Component has a version') {
      return onVersionOpen()
    }

    // COMPONENT ADD SUPPLIER MODAL
    if (shortDesc === 'Component has a supplier') {
      return onSupplierOpen()
    }

    // COMPONENT HAS RELATIONSHIP
    if (shortDesc === 'Component has relationship/s') {
      getComPath({
        variables: { compId: row?.component?.id, sbomId: sbomId }
      }).then((res) => res?.data && onRelOpen())
    }

    // PURL MODAL
    if (
      shortDesc === 'Component has a purl' ||
      shortDesc === 'Component has a valid purl'
    ) {
      prodCompDispatch({
        type: 'SET_PURL_STRING',
        payload: 'pkg:type/name@version'
      })
      return onPurlOpen()
    }

    // CPE MODAL
    if (
      shortDesc === 'Component has a valid cpe' ||
      shortDesc === 'Component has a cpe'
    ) {
      prodCompDispatch({
        type: 'SET_CPE_STRING',
        payload: 'cpe:2.3:::::*:*:*:*:*:*:*'
      })
      return onCpeOpen()
    }

    // COMPONENT LICENSE SELECTOR MODAL
    if (
      shortDesc === 'Component has license/s specified' ||
      shortDesc === 'Componet has deprecated license/s' ||
      shortDesc === 'Component has restrictive licenses specified'
    ) {
      return handleOpenLicense()
    }
  }

  const handleCreateCpe = (string) => {
    const cpeItem = cpeList?.find((item) => item === string)
    if (cpeItem) {
      showToast({
        description: 'CPE already exists',
        status: 'error'
      })
    } else {
      setCpeList([...cpeList, string])
      setCpeValue('')
      setSelectedCpe(null)
      onCpeClose()
    }
  }

  const handleUpdateCpe = (string, id) => {
    const cpeItem = cpeList?.find((item) => item === string)
    if (cpeItem) {
      showToast({
        description: 'CPE already exists',
        status: 'error'
      })
    } else if (cpeList?.find((item, index) => index === id)) {
      const updatedData = cpeList?.map((item, index) => {
        if (index === id) {
          return string
        }
        return item
      })
      setCpeList(updatedData)
      setCpeValue('')
      setSelectedCpe(null)
    }
  }

  const onCheckOpen = (row) => {
    setActiveRow(row)
    const { component, organizationRule } = row
    const { name, version } = component || ''
    const { shortDesc, friendlyId } = organizationRule?.rule || ''
    if (shortDesc === 'Component has a unique identifier') {
      handleComUpdate(row)
    } else if (shortDesc === 'Document has a unique identifier') {
      handleSbomUpdate(row)
    } else {
      getRules({
        variables: {
          id: productId,
          checkIdentifier: friendlyId,
          checkComponent: component ? name : undefined,
          checkVersion: component ? version : undefined
        }
      })
        .then((res) => {
          console.log(res?.data)
          const result = res?.data?.project?.automationRules?.nodes
          if (result?.length > 0) {
            setRuleExists(true)
          } else {
            setRuleExists(false)
          }
        })
        .finally(() => handleOpen(row))
    }
  }

  const updateIssue = async (id) => {
    await updateResult({
      variables: {
        id: id,
        status: 'ignored'
      }
    })
  }

  // COLUMNS
  const columns = [
    // HEALTH CHECK ID
    {
      id: 'RULES_FRIENDLY_ID',
      name: 'CHECK ID',
      selector: (row) => {
        const { organizationRule } = row
        return <Text color={textColor}>{organizationRule.rule.friendlyId}</Text>
      },
      sortable: true,
      width: '10%'
    },
    // SEVERITY
    {
      id: 'ORGANIZATION_RULES_SEVERITY',
      name: 'SEVERITY',
      selector: (row) => {
        const { organizationRule } = row
        return (
          <Tag
            size='md'
            variant='subtle'
            colorScheme={sevColor(organizationRule.severity)}
            textTransform={'capitalize'}
            width={'80px'}
          >
            <TagLabel mx={'auto'}>{organizationRule.severity}</TagLabel>
          </Tag>
        )
      },
      width: '10%',
      sortable: true
    },
    // LONG DESCRIPTION
    {
      id: 'COMPONENTS_NAME',
      name: 'DESCRIPTION',
      selector: (row) => {
        const { organizationRule, component } = row
        return (
          <Stack spacing={2} my={3}>
            {component !== null && (
              <Box
                width={'fit-content'}
                onClick={() => setActiveRow(component)}
              >
                <RowComponent content={component} />
              </Box>
            )}
            <Text color={textColor}>
              {organizationRule.rule.longDesc !== null
                ? `${organizationRule.rule.shortDesc?.substring(0, 300)}${
                    organizationRule.rule.shortDesc.length > 300 ? '...' : ''
                  }`
                : ''}
            </Text>
          </Stack>
        )
      },
      sortable: true,
      wrap: true
    },
    // UPDATED AT
    {
      id: 'CHECK_RESULTS_UPDATED_AT',
      name: 'UPDATED',
      selector: (row) => (
        <Tooltip label={getFullDateAndTime(row.updatedAt)} placement={'top'}>
          <Text color={textColor}>{timeSince(row.updatedAt)}</Text>
        </Tooltip>
      ),
      sortable: true,
      sortFunction: (a, b) => {
        const dateA = new Date(a.updatedAt)
        const dateB = new Date(b.updatedAt)
        return dateA - dateB // Sort in descending order
      },
      right: 'true'
    },
    // ACTION
    {
      id: 'RESOLUTION',
      name: 'RESOLUTION',
      selector: (row) => {
        const { status, id, componentId } = row
        console.log(row)
        const { friendlyId } = row?.organizationRule?.rule || ''
        const fixedIDs = ['SB-HC-4', 'SB-HC-5', 'SB-HC-6', 'SB-HC-16']
        const fixedByDefault = fixedIDs.includes(friendlyId)
        const isPrimary = friendlyId === 'SB-HC-10'
        const isEditable = componentId ? updateComp : editChecks
        return (
          <>
            {status === 'unresolved' && !fixedByDefault && (
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Tooltip label='Fix'>
                  <IconButton
                    size='sm'
                    variant='solid'
                    colorScheme='blue'
                    fontWeight='normal'
                    icon={<BiSolidWrench size={18} />}
                    onClick={() => onCheckOpen(row)}
                    disabled={customerView || !isEditable}
                  />
                </Tooltip>

                <Tooltip label='Ignore'>
                  <IconButton
                    size='sm'
                    variant='solid'
                    colorScheme='blue'
                    fontWeight='normal'
                    icon={<GoSkip size={18} />}
                    onClick={() => updateIssue(id)}
                    disabled={customerView || !editChecks}
                  />
                </Tooltip>
              </Stack>
            )}

            {fixedByDefault && (
              <Button
                size='sm'
                variant='solid'
                fontSize={'xs'}
                fontWeight='normal'
                colorScheme='whatsapp'
                leftIcon={<CheckIcon />}
                onClick={() => (isPrimary ? null : onFixedOpen())}
                disabled={customerView || !editChecks}
              >
                {isPrimary ? 'Fixed' : 'View'}
              </Button>
            )}

            {!fixedByDefault && status === 'resolved' && (
              <Button
                size='sm'
                fontSize={'xs'}
                variant='solid'
                colorScheme='whatsapp'
                leftIcon={<CheckIcon />}
                onClick={() => (isPrimary ? null : onCheckOpen(row))}
                isLoading={activeRow?.id === id && loadingRules}
              >
                {isPrimary ? 'Fixed' : 'View'}
              </Button>
            )}

            {status === 'ignored' && (
              <Button size='sm' width={'74px'} fontSize={'xs'} variant='solid'>
                Ignored
              </Button>
            )}
          </>
        )
      },
      width: '12%',
      right: 'true'
    }
  ]

  // SORTING
  const handleSort = (column, sortDirection) => {
    setCheckState((oldFilters) => ({
      ...oldFilters,
      field: column?.id,
      direction: sortDirection.toUpperCase()
    }))
  }

  return (
    <>
      <Flex flexDir={'column'} width={'100%'}>
        <DataTable
          subHeader
          data={nodes}
          persistTableHead
          columns={columns}
          responsive={true}
          onSort={handleSort}
          defaultSortAsc={false}
          progressPending={loading}
          customStyles={customStyles(headColor)}
          subHeaderComponent={subHeader}
          progressComponent={<CustomLoader />}
          defaultSortFieldId={checkState?.field}
        />
        {/* PAGINATION */}
        <Pagination {...paginationProps} />
      </Flex>

      {/* ACTIONS */}
      {activeRow !== null && (
        <>
          {/* SBOM DATA LICENSES DRAWER */}
          {isDataLicenseOpen && (
            <LicenseModal
              data={sbomData}
              activeRow={activeRow}
              isOpen={isDataLicenseOpen}
              onClose={onDataLicenseClose}
            />
          )}

          {/* COMPONENT PRIMARY MODAL */}
          {isPrimaryOpen && (
            <CheckModal
              activeRow={activeRow}
              isOpen={isPrimaryOpen}
              ruleExists={ruleExists}
              onClose={onPrimaryClose}
            />
          )}

          {/* COMPONENT VERSION MODAL */}
          {isVersionOpen && (
            <CheckModal
              activeRow={activeRow}
              isOpen={isVersionOpen}
              ruleExists={ruleExists}
              onClose={onVersionClose}
            />
          )}

          {/* COMPONENT LICENSE MODAL */}
          {isLicenseOpen && (
            <CheckModal
              activeRow={activeRow}
              isOpen={isLicenseOpen}
              ruleExists={ruleExists}
              onClose={onLicenseClose}
            />
          )}

          {/* COMPONENT TYPE MODAL */}
          {isTypeOpen && (
            <CheckModal
              isOpen={isTypeOpen}
              onClose={onTypeClose}
              activeRow={activeRow}
              ruleExists={ruleExists}
            />
          )}

          {/* SUPPLIER MODAL */}
          {isSupplierOpen && (
            <SupplierModal
              id={null}
              data={null}
              activeRow={activeRow}
              isOpen={isSupplierOpen}
              ruleExists={ruleExists}
              onClose={onSupplierClose}
            />
          )}

          {isOpen && (
            <CheckModal
              isOpen={isOpen}
              onClose={onClose}
              activeRow={activeRow}
              ruleExists={ruleExists}
            />
          )}

          {/* PURL MODAL */}
          {isPurlOpen && (
            <PurlModal
              data={null}
              getCpe={getCpe}
              isOpen={isPurlOpen}
              purlValue={purlValue}
              activeRow={activeRow}
              onClose={onPurlClose}
              ruleExists={ruleExists}
              setPurlValue={setPurlValue}
            />
          )}

          {/* CPE MODAL */}
          {isCpeOpen && (
            <CpeModal
              data={null}
              getCpe={getCpe}
              activeComp={null}
              isOpen={isCpeOpen}
              cpeValue={cpeValue}
              onClose={onCpeClose}
              activeRow={activeRow}
              ruleExists={ruleExists}
              selectedCpe={selectedCpe}
              setCpeValue={setCpeValue}
              onCreateCpe={handleCreateCpe}
              onUpdateCpe={handleUpdateCpe}
            />
          )}

          {/* CREATION TOOLS DRAWER */}
          {isCreationOpen && (
            <GeneralDataDrawer
              data={null}
              selectedKey={'tools'}
              activeRow={activeRow}
              isOpen={isCreationOpen}
              ruleExists={ruleExists}
              btnRef={creationToolBtn}
              onClose={onCreationClose}
            />
          )}

          {/* AUTHOR DRAWER */}
          {isAuthorOpen && (
            <GeneralDataDrawer
              data={null}
              getCpe={getCpe}
              btnRef={authorBtn}
              activeRow={activeRow}
              isOpen={isAuthorOpen}
              selectedKey={'author'}
              onClose={onAuthorClose}
              ruleExists={ruleExists}
            />
          )}

          {/* DOCUMENT SUPPLIER DRAWER */}
          {isDocSupOpen && (
            <PriSupplierModal
              getCpe={getCpe}
              suppliers={null}
              activeRow={activeRow}
              isOpen={isDocSupOpen}
              ruleExists={ruleExists}
              onClose={onDocSupClose}
            />
          )}

          {/* COMPONENT RELATIONSHIP DRAWER */}
          {isRelOpen && (
            <RelationshipDrawer
              data={null}
              isOpen={isRelOpen}
              fetchCompData={refetch}
              onClose={onRelClose}
              activeRow={activeRow}
              ruleExists={ruleExists}
              compPath={pathToPrimary}
            />
          )}
        </>
      )}

      {isFixedOpen && (
        <FixedModal isOpen={isFixedOpen} onClose={onFixedClose} />
      )}
    </>
  )
}

export default Checks
