import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Flex, Table, Tbody, Text, Th, Thead, Tr } from '@chakra-ui/react'

import VulLinkRow from '../Tables/VulLinkRow'

const sortData = (data) => {
  return [...data].sort((a, b) => {
    const dateA = new Date(a.updatedAt).getTime()
    const dateB = new Date(b.updatedAt).getTime()
    return dateB - dateA
  })
}

const VexStatusHistoryTable = ({ data }) => {
  const location = useLocation()
  const path = location?.pathname?.startsWith('/vendor') ? 'vendor' : 'customer'
  const sortedComponentVulnLogs = useMemo(() => sortData(data), [data])

  const vendorHeads = [
    'Status',
    'Justification',
    'Impact',
    'Notes',
    'By',
    'Created'
  ]
  const customerHeads = vendorHeads?.filter((item) => item !== 'Notes')
  const columns = path === 'customer' ? customerHeads : vendorHeads

  return (
    <Flex flexDir={'column'}>
      <Text size='md' my={2}>
        Status History
      </Text>
      {sortedComponentVulnLogs.length > 0 ? (
        <Table variant='simple' size='sm' my={2}>
          <Thead>
            <Tr my='.8rem'>
              {columns?.map((item, index) => (
                <Th key={index} color='gray.500' pl={0}>
                  <Box>{item}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {sortedComponentVulnLogs?.map(
              (item) =>
                item && (
                  <VulLinkRow
                    key={item.id}
                    id={item.id}
                    username={item.changedBy}
                    justification={item.justification}
                    status={item.status}
                    timestamp={item.updatedAt}
                    note={item.note}
                    impact={item.impact}
                  />
                )
            )}
          </Tbody>
        </Table>
      ) : (
        <Text color={'darkgrey'}>No status history found</Text>
      )}
    </Flex>
  )
}

export default VexStatusHistoryTable
