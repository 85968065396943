export const ProductDetailsTabs = {
  VERSIONS: 'versions',
  VULNERABILITIES: 'vulnerabilities',
  AUTOMATION_RULES: 'automation rules',
  SETTINGS: 'settings',
  POLICIES: 'policies',
  CHANGE_LOG: 'change log'
}

export const ProductGeneralTabs = {
  GENERAL: 'general',
  PARTS: 'parts',
  COMPONENTS: 'components',
  VULNERABILITIES: 'vulnerabilities',
  LICENSES: 'licenses',
  POLICIES: 'policies',
  SUPPORT: 'support',
  CHECKS: 'checks',
  CHANGELOG: 'changelog'
}
