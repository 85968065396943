import { useMutation, useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CheckIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useColorModeValue
} from '@chakra-ui/react'

import Card from 'components/Card/Card'

import { useHasPermission } from 'hooks/useHasPermission'

import { updateCompVulnVex } from 'graphQL/Mutation'
import { ComponentVulnLogs, ShareCompVulnLogs } from 'graphQL/Queries'

import { FaTimes } from 'react-icons/fa'

import InfoLabel from './InfoLabel'
import VexStatusHistoryTable from './VexStatusHistoryTable'

const VexStatusComponent = ({ data, allVexStatus, allVexJustify, allCdx }) => {
  const { id, fixedVersions } = data || ''
  const paramz = useParams()
  const sbomId = paramz.sbomid

  const borderColor = useColorModeValue('#E2E8F0', '#2D3748')

  const editVulns = useHasPermission({
    parentKey: 'view_sbom',
    childKey: 'edit_vulnerabilities'
  })

  const signedUrlParams = sessionStorage.getItem('signedUrlParams')
  const { data: componentVulnLogs } = useQuery(
    signedUrlParams ? ShareCompVulnLogs : ComponentVulnLogs,
    {
      variables: { vexLogableId: id }
    }
  )

  const [statusName, setStatusName] = useState('')
  const [vexStatusId, setVexStatusId] = useState('')

  const [vexJustificationId, setVexJustificationId] = useState('')

  const [cdxResponseId, setCdxResponseId] = useState('')
  const [responseTitle, setResponseTitle] = useState('')

  const [fixedIn, setFixedIn] = useState('')
  const [detail, setDetail] = useState('')
  const [note, setNote] = useState('')
  const [impact, setImpact] = useState('')
  const [action, setAction] = useState('')

  const [propagateVex, setPropagateVex] = useState(false)
  const [params, setParams] = useState({})

  const [saveButtonDisable, setSaveButtonDisable] = useState(false)
  const [propagateDisable, setPropagateDisable] = useState(false)

  const [statusCheckType, setStatusCheckType] = useState('')
  const [detailsCheckType, setDetailsCheckType] = useState('')
  const [notesCheckType, setNotesCheckType] = useState('')

  const [locallySavedLabel, setLocallySavedLabel] = useState(null)

  useEffect(() => {
    if (data) {
      setStatusName(data.vexStatus?.name)
      setVexStatusId(data.vexStatus?.id)
      setVexJustificationId(data.vexJustification?.id)
      setResponseTitle(data.cdxResponse?.name)
      setCdxResponseId(data.cdxResponse?.id)
      setFixedIn(data.fixedIn)
      setDetail(data.detail)
      setNote(data.note)
      setImpact(data.impact)
      setAction(data.actionStmt)
    }
  }, [data])

  useEffect(() => {
    generateControl(statusName)
    setLocallySavedLabel(null)
  }, [
    note,
    detail,
    impact,
    action,
    fixedIn,
    vexStatusId,
    vexJustificationId,
    cdxResponseId,
    propagateVex,
    generateControl,
    statusName
  ])

  const handleStatusChange = (e) => {
    const { value } = e.target
    const status = e.target.options[e.target.selectedIndex].text

    setStatusName(status)
    setVexStatusId(value)

    generateControl(status)
  }

  const handleResponseChange = (e) => {
    const { value } = e.target
    const title = e.target.options[e.target.selectedIndex].text

    setResponseTitle(title)
    setCdxResponseId(value)
  }

  const handleJustifyChange = (e) => {
    const { value } = e.target
    setVexJustificationId(value)
  }

  const handleFixedInChange = (e) => {
    const { value } = e.target
    setFixedIn(value)
  }

  const [compVexCreate] = useMutation(updateCompVulnVex)

  const checkEmpty = (value) => value || undefined

  const generateControl = useCallback(
    (status) => {
      switch (status) {
        case 'In Triage':
          setParams({
            sbomId: checkEmpty(sbomId),
            compVulnId: checkEmpty(id),
            vexStatusId: checkEmpty(vexStatusId),
            propagateVex: checkEmpty(propagateVex),
            detail: checkEmpty(detail),
            note: checkEmpty(note)
          })

          setSaveButtonDisable(false)
          setPropagateDisable(false)

          setStatusCheckType('green')
          detail ? setDetailsCheckType('green') : setDetailsCheckType('grey')
          note ? setNotesCheckType('green') : setNotesCheckType('grey')
          break

        case 'Not Affected':
          setParams({
            sbomId: checkEmpty(sbomId),
            compVulnId: checkEmpty(id),
            vexStatusId: checkEmpty(vexStatusId),
            propagateVex: checkEmpty(propagateVex),
            note: checkEmpty(note),
            vexJustificationId: checkEmpty(vexJustificationId),
            impact: checkEmpty(impact)
          })

          setSaveButtonDisable(false)

          setStatusCheckType('green')

          if (!vexJustificationId && !impact) {
            setDetailsCheckType('red')
            setPropagateDisable(true)
          } else {
            setDetailsCheckType('green')
            setPropagateDisable(false)
          }

          note ? setNotesCheckType('green') : setNotesCheckType('grey')

          break

        case 'Affected':
          setParams({
            sbomId: checkEmpty(sbomId),
            compVulnId: checkEmpty(id),
            vexStatusId: checkEmpty(vexStatusId),
            propagateVex: checkEmpty(propagateVex),
            note: checkEmpty(note),
            cdxResponseId: checkEmpty(cdxResponseId),
            action: checkEmpty(action),
            fixedIn: checkEmpty(fixedIn)
          })

          setSaveButtonDisable(false)
          setPropagateDisable(!note && !action && !cdxResponseId && !fixedIn)

          setStatusCheckType('green')

          if (!cdxResponseId && !action) {
            setDetailsCheckType('red')
            setPropagateDisable(true)
          } else if (responseTitle == 'Update' && !fixedIn) {
            setSaveButtonDisable(true)
            setPropagateDisable(true)
            setDetailsCheckType('red')
          } else {
            setDetailsCheckType('green')
          }

          note ? setNotesCheckType('green') : setNotesCheckType('grey')
          break

        case 'Fixed':
          setParams({
            sbomId: checkEmpty(sbomId),
            compVulnId: checkEmpty(id),
            vexStatusId: checkEmpty(vexStatusId),
            propagateVex: checkEmpty(propagateVex),
            note: checkEmpty(note)
          })

          setSaveButtonDisable(false)
          setPropagateDisable(false)

          setStatusCheckType('green')
          setNotesCheckType('green')
          break

        default:
          setParams({
            sbomId: checkEmpty(sbomId),
            compVulnId: checkEmpty(id),
            vexStatusId: checkEmpty(vexStatusId),
            note: checkEmpty(note)
          })

          setSaveButtonDisable(!note)
          setPropagateDisable(true)

          if (note) {
            setNotesCheckType('green')
            setStatusCheckType('green')
          } else {
            setNotesCheckType(null)
            setStatusCheckType(null)
          }

          break
      }
    },
    [
      action,
      cdxResponseId,
      detail,
      fixedIn,
      id,
      impact,
      note,
      propagateVex,
      responseTitle,
      sbomId,
      vexJustificationId,
      vexStatusId
    ]
  )

  const generateIcon = (checkIconState) => {
    switch (checkIconState) {
      case 'grey':
        return (
          <IconButton
            size='xs'
            position={'absolute'}
            left={'-4%'}
            top={'49%'}
            colorScheme='gray'
            rounded={'full'}
            icon={<CheckIcon />}
          />
        )
      case 'green':
        return (
          <IconButton
            size='xs'
            position={'absolute'}
            left={'-4%'}
            top={'49%'}
            colorScheme='green'
            rounded={'full'}
            icon={<CheckIcon />}
          />
        )
      case 'red':
        return (
          <IconButton
            size='xs'
            position={'absolute'}
            left={'-4%'}
            top={'49%'}
            colorScheme='red'
            rounded={'full'}
            icon={<FaTimes />}
          />
        )
      default:
        return null
    }
  }

  const handleSave = () => {
    compVexCreate({
      variables: params
    }).then(({ data }) => {
      if (data?.componentVexUpdate?.errors?.length === 0) {
        setLocallySavedLabel('Saved')
        setSaveButtonDisable(true)
      }
    })
  }

  const textColor = useColorModeValue('#1A202C', '#F7FAFC')

  return (
    <>
      <Stack spacing='24px'>
        <Box color={textColor}>
          <SimpleGrid row={5} spacing={4}>
            {!signedUrlParams && (
              <>
                {/* STATUS */}
                <Card
                  position='relative'
                  p={6}
                  border={`1px solid ${borderColor}`}
                >
                  {generateIcon(statusCheckType)}
                  <FormControl isRequired>
                    <InfoLabel title={'Status'} name={'vexType'} />
                    <Select
                      id='vexType'
                      name='vexType'
                      fontSize='sm'
                      value={vexStatusId}
                      isDisabled={!editVulns}
                      onChange={handleStatusChange}
                    >
                      <option value=''>-- Select Status --</option>
                      {allVexStatus ? (
                        allVexStatus.vexStatuses.map((st, idx) => (
                          <option key={idx} value={st.id}>
                            {st.name}
                          </option>
                        ))
                      ) : (
                        <option value={''}>No data found</option>
                      )}
                    </Select>
                  </FormControl>
                </Card>

                {/* JUSTIFICATION */}
                {statusName === 'Not Affected' && (
                  <Card
                    position='relative'
                    p={6}
                    border={`1px solid ${borderColor}`}
                  >
                    {generateIcon(detailsCheckType)}
                    <FormControl isRequired>
                      <InfoLabel
                        title={'Justification'}
                        name={vexJustificationId}
                      />
                      <Select
                        fontSize='sm'
                        id='vexJustificationId'
                        name='vexJustificationId'
                        value={vexJustificationId}
                        isDisabled={!editVulns}
                        onChange={handleJustifyChange}
                      >
                        <option value=''>-- Select --</option>
                        {allVexJustify ? (
                          allVexJustify.vexJustifications?.map(
                            (justify, idx) => (
                              <option key={idx} value={justify.id}>
                                {justify.name}
                              </option>
                            )
                          )
                        ) : (
                          <option value={''}>No data found</option>
                        )}
                      </Select>
                    </FormControl>
                    <Text
                      mt={5}
                      fontSize='sm'
                      color={textColor}
                      fontWeight={'medium'}
                    >
                      AND / OR
                    </Text>
                    {/* IMPACT STATEMENT */}
                    <FormControl mt={5} isRequired>
                      <InfoLabel
                        name={'impactStatement'}
                        title={'Impact Statement'}
                      />
                      <Textarea
                        rows={2}
                        type='text'
                        fontSize='sm'
                        value={impact}
                        id='impactStatement'
                        name='impactStatement'
                        isDisabled={!editVulns}
                        onChange={(e) => setImpact(e.target.value)}
                        placeholder='Exmple: The product is not affected by this vulnerability because ....'
                      />
                    </FormControl>
                  </Card>
                )}

                {/* RESPONSE */}
                {statusName === 'Affected' && (
                  <Card
                    p={6}
                    position='relative'
                    border={`1px solid ${borderColor}`}
                  >
                    {generateIcon(detailsCheckType)}
                    {/* ACTION STATEMENT */}
                    <FormControl isRequired>
                      <InfoLabel
                        title={'Action Statement'}
                        name={'actionStatement'}
                      />
                      <Textarea
                        rows={2}
                        name='actionStatement'
                        id='actionStatement'
                        placeholder='Example: This vulnerability can be mitigate by running the application with ENV_PROTECTED enabled or turning off Notifications under settings.'
                        fontSize='sm'
                        value={action}
                        isDisabled={!editVulns}
                        onChange={(e) => setAction(e.target.value)}
                      />
                    </FormControl>
                    {/* RESPONSE */}
                    <FormControl mt={5}>
                      <InfoLabel title={'Response'} name={'cdxResponseId'} />
                      {allCdx && (
                        <Select
                          fontSize='sm'
                          id='cdxResponseId'
                          name='cdxResponseId'
                          value={cdxResponseId}
                          isDisabled={!editVulns}
                          onChange={handleResponseChange}
                        >
                          <option value=''>-- Select --</option>
                          {allCdx?.cdxResponses?.length > 0 &&
                            allCdx?.cdxResponses?.map((item, idx) => (
                              <option key={idx} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </Select>
                      )}
                    </FormControl>
                    {/* FIXED VERSION */}
                    {responseTitle === 'Update' && (
                      <FormControl
                        mt={5}
                        width={'100%'}
                        isRequired={responseTitle === 'Update'}
                      >
                        <InfoLabel title={'Fixed Version'} name={'fixedIn'} />
                        <Select
                          id='fixedIn'
                          fontSize='sm'
                          name='fixedIn'
                          value={fixedIn}
                          isDisabled={!editVulns}
                          onChange={handleFixedInChange}
                        >
                          <option value=''>-- Select --</option>
                          {fixedVersions?.length > 0 ? (
                            fixedVersions?.map((item, index) => (
                              <option key={index} value={item} name={item}>
                                {item}
                              </option>
                            ))
                          ) : (
                            <option value=''>-- --</option>
                          )}
                        </Select>
                      </FormControl>
                    )}
                  </Card>
                )}

                {/* DETAILS */}
                {statusName === 'In Triage' && (
                  <Card
                    position='relative'
                    p={6}
                    border={`1px solid ${borderColor}`}
                  >
                    {generateIcon(detailsCheckType)}
                    <FormControl>
                      <InfoLabel title={'Details'} name={'details'} />
                      <Textarea
                        rows={2}
                        name='details'
                        id='details'
                        isDisabled={!editVulns}
                        placeholder='Example: The vulnerability surfaced in the reports on March 13th 3pm and has been sent to PSIRT for analysis by 7pm.'
                        fontSize='sm'
                        value={detail}
                        onChange={(e) => setDetail(e.target.value)}
                      />
                    </FormControl>
                  </Card>
                )}

                {/* INTERNAL NOTES */}
                <Card
                  position='relative'
                  p={6}
                  border={`1px solid ${borderColor}`}
                >
                  {generateIcon(notesCheckType)}
                  <FormControl mt={statusName === 'In Triage' ? 5 : 0}>
                    <InfoLabel
                      title={'Internal Notes'}
                      name={'internalNotes'}
                    />
                    <Textarea
                      rows={2}
                      name='internalNotes'
                      id='internalNotes'
                      isDisabled={!editVulns}
                      placeholder='Example: John Appleseed has scanned the codebase and found two instances of function alls encrypt(). Next step: exploitability analysis.'
                      fontSize='sm'
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </FormControl>
                </Card>
                {/* UPSTERAM PRODUCT */}
                <FormControl>
                  <Checkbox
                    size='sm'
                    isChecked={propagateVex}
                    onChange={(e) => setPropagateVex(e.target.checked)}
                    isDisabled={propagateDisable || !editVulns}
                  >
                    Also update upstream products
                  </Checkbox>
                </FormControl>
              </>
            )}
            {!signedUrlParams && (
              <Button
                width={'fit-content'}
                colorScheme='blue'
                onClick={handleSave}
                fontSize={'sm'}
                isDisabled={saveButtonDisable || !editVulns}
              >
                {locallySavedLabel || 'Save'}
              </Button>
            )}
            {componentVulnLogs && (
              <VexStatusHistoryTable
                data={componentVulnLogs.componentVulnLogs}
              />
            )}
          </SimpleGrid>
        </Box>
      </Stack>
    </>
  )
}

export default VexStatusComponent
